a {
	color: var(--primary);
}

.primary {
	color: var(--primary);
}

.bg-primary {
	background-color: var(--primary);
}

.indent-n2em {
	margin-left: 0.5em;
	text-indent: -1em;
}
